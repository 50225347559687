import Header from "../../components/Header";

export default function PrivacyPolicy() {
  return (
    <>
      <Header />
      <div className='flex flex-col p-20 items-center'>
        <div className='text-3xl text-white font-bold'>Privacy Policy</div>
        <div className='mt-8 text-white'>
          The game "Space Juice" made by the company "TouchUp Studios Inc" does not collect or store personal data,
          nor does it upload any data from the game to any online services.
          <br/><br/>
          If you installed this application from Google Play, then Google Play does collect some personal data.
          <br/>
          Please see the privacy policy for Google Play Services here: <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>
          
          <br/><br/>
          If you have any questions about this privacy policy please contact us at <strong>spacejuicesupp@gmail.com</strong>
        </div>
      </div>
    </>
  )
}