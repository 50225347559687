import React, { useState, useCallback } from "react";
import ImageViewer from 'react-simple-image-viewer';

import Header from "../../components/Header";
import SpaceJuiceLogo from '../../assets/SpaceJuice/SpaceJuiceLogo.png';
import Screenshot001  from '../../assets/SpaceJuice/Screenshot001.jpg';
import Screenshot002  from '../../assets/SpaceJuice/Screenshot002.jpg';
import Screenshot003  from '../../assets/SpaceJuice/Screenshot003.jpg';
import Screenshot004  from '../../assets/SpaceJuice/Screenshot004.jpg';

const SpaceJuice = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const images = [
    Screenshot001,
    Screenshot002,
    Screenshot003,
    Screenshot004,
  ];

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <>
      <Header />
      <div className='flex justify-center mt-8'>
        <div className="flex flex-col md:flex-row" >
          <div className="flex md:w-1/2 items-center justify-center">
            <img className="w-[80%]" src={SpaceJuiceLogo} alt="/" />
          </div>
          
          <div className='flex md:w-1/2 items-center justify-center' >
            
            <iframe
              className="w-[80%] h-[300px]"
              src={`https://www.youtube.com/embed/nnbHw0N83zo`}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />
            
            {/*
            <div className="flex justify-center">
              <div class="grid grid-cols-2 gap-4 m-4 max-w-[700px]">
                {images.map((src, index) => (
                  <img
                    key={ index }
                    class="h-auto max-w-full rounded-lg cursor-zoom-in"
                    src={ src }
                    onClick={ () => openImageViewer(index) }
                    alt="/"
                  />
                ))}
              </div>
            </div>
            */}
          </div>
        </div>
      </div>
      

      {isViewerOpen && (
        <ImageViewer
          src={ images }
          currentIndex={ currentImage }
          disableScroll={ false }
          closeOnClickOutside={ true }
          onClose={ closeImageViewer }
        />
      )}
    </>
  )
}

export default SpaceJuice;