import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home.js';
import SpaceJuice from './pages/spacejuice/SpaceJuice.js';
import SpaceJuicePrivacyPolicy from './pages/spacejuice/PrivacyPolicy.js';
import NoPage from './pages/NoPage.js';

function App() {
  return (
    <div className='h-screen bg-[#060612]'>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home/>} />
          <Route path="/home" element={<Home/>} />
          <Route path="/spacejuice" element={<SpaceJuice/>} />
          <Route path="/spacejuice/privacy" element={<SpaceJuicePrivacyPolicy/>} />
          <Route path="*" element={<NoPage/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
