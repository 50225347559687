import React, { useState } from "react"
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import Logo from '../assets/TouchUpStudiosLogo.png';

const Header = () => {
  const [nav, setNav] = useState(true);

  const handleNav = () => {
    setNav(!nav);
  }

  return (
    <>
      <div className="flex flex-row pt-2 h-28 bg-[#060612]">
        <div className="w-1/3 flex justify-between items-center h-24 px-4">
          <ul className="hidden md:flex flex-wrap p-2">
            <li className='px-4 text-white font-bold text-nowrap'><a href="/home">Home</a></li>
            <li className='px-4 text-white font-bold text-nowrap'><a href="/spacejuice">Space Juice</a></li>
            <li className='px-4 text-white font-bold text-nowrap'><a href="/spacejuice/privacy">Privacy</a></li>
          </ul>
          
        </div>

        <div className='flex items-center justify-center w-1/3'>
          <img className="absolute w-[180px] h-[110px]" src={Logo} alt="/" />
        </div>

        
        <div className='flex  items-center justify-end pr-12 w-1/3 md:hidden'>
          <div className='md:hidden'>
            <div onClick={handleNav} className='text-white block cursor-pointer'>
              {!nav ? <AiOutlineClose size={24} /> : <AiOutlineMenu size={24} />}
            </div>
            <div className={!nav ? "fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900  bg-[#060612] ease-in-out duration-200" : "fixed left-[-100%]"}>
              <ul >
                <li className="p-4 text-white font-bold border-b border-gray-600"><a href="/home">Home</a></li>
                <li className="p-4 text-white font-bold border-b border-gray-600"><a href="/spacejuice">Space Juice</a></li>
                <li className="p-4 text-white font-bold border-b border-gray-600"><a href="/spacejuice/privacy">Privacy</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;