import Header from '../components/Header';
import SpaceJuiceLogo from '../assets/SpaceJuice/SpaceJuiceLogo.png';

export default function Home() {
  return (
    <>
      <Header />
      <div className='flex flex-col mt-8'>
        <div className='flex w-full items-center justify-center'>
          <div className='text-3xl text-white'>Our Games</div>
        </div>
        <div className='flex justify-center mt-8'>
        <div className="flex flex-col md:flex-row" >
          <div className="flex md:w-1/2 items-center justify-center">
            <img className="w-[80%]" src={SpaceJuiceLogo} alt="/" />
          </div>
          
          <div className='flex md:w-1/2 items-center justify-center' >
            
            <iframe
              className="w-[80%] h-[300px]"
              src={`https://www.youtube.com/embed/nnbHw0N83zo`}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />
            
            {/*
            <div className="flex justify-center">
              <div class="grid grid-cols-2 gap-4 m-4 max-w-[700px]">
                {images.map((src, index) => (
                  <img
                    key={ index }
                    class="h-auto max-w-full rounded-lg cursor-zoom-in"
                    src={ src }
                    onClick={ () => openImageViewer(index) }
                    alt="/"
                  />
                ))}
              </div>
            </div>
            */}
          </div>
        </div>
      </div>
      </div>
    </>
  )
};